import React, { useState, useContext, useMemo } from "react"




const Settings = (props) => {

   const user = props.User
  
    return (
        <div className="row h-100" >

          
            <div className="col-12 h-100">
                <div className={`custom-card animate__animated animate__fadeIn`} style={{overflowY:'auto'}}>
                    <div className="row">
                        <div className="col-12">
                           
                            <div 
                                className="w-50 text-center" 
                                style={{
                                    backgroundColor: '#f5f5f599',
                                    borderRadius: 12,
                                    padding: 15,
                                    margin: 'auto'
                                }}
                            >
                                <img className="mb-3" style={{width:125,height:125,borderRadius:'100%'}} src={user.profilePic} />
                                <h6>{user.displayName}</h6>
                            </div>
                            
                        </div>
                    </div>

                    <br/> 

                 
                   
                    <div className="d-flex text-center w-50" style={{margin:'auto',marginTop:'2%'}}>
                        <div class="d-flex settingsCard">
                            <p class="mb-0 w-100 text-center"><b>Version Number -</b> 0.8 Beta / a</p>
                        </div>
                    </div>
              
                </div>
            </div>
           
        </div>
    )
}

export default Settings