import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged   } from "firebase/auth";
import { getFirestore, getDoc, collection, addDoc,doc, updateDoc, increment, setDoc, query, onSnapshot } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyCmFx0H9dj14ArjkZvWCgYoG20ytgEnN88",
    authDomain: "universauto-93091.firebaseapp.com",
    projectId: "universauto-93091",
    storageBucket: "universauto-93091.appspot.com",
    messagingSenderId: "186297477169",
    appId: "1:186297477169:web:b172809ae7846f7bfacd10",
    measurementId: "G-2HGXJ290Y1"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
auth.languageCode = 'fr';
const provider = new GoogleAuthProvider();

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
const currentDate = new Date();
const year = '' + currentDate.getFullYear() + ''
const month = months[currentDate.getMonth()]; // Get the three-letter month abbreviation



export let trackVisits = async () => {
    let tracked = localStorage.getItem('universauto')
    let status = null
    
    if(tracked) status = true
    else{
        localStorage.setItem('universauto',true)
        status = false
    }


    await updateDoc(doc(db, "Stats", "Visits"), {
        'total': increment(1),
        'unique':  status? increment(0) : increment(1)
    });

    await updateDoc(doc(db, "Stats", "Visits", "Yearly", year), {
        [`monthly.${month}.total`]: increment(1),
        [`monthly.${month}.unique`]:  status? increment(0) : increment(1),
        'total': increment(1),
        'unique':  status? increment(0) : increment(1)
    });
}   

export let getRequests = (setData, setRequestsLoaded) => {
    const q = query(collection(db, "Requests"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let arr = []
        querySnapshot.forEach((doc) => {
            let data = doc.data()
            arr.push({
                id: doc.id,
                comments: data.comments,
                dates: data.dates,
                destination: data.destination,
                email: data.email,
                name:data.name,
                phone:data.phone,
                solved:data.solved,
                submitted:data.submitted,
                type:data.type 
            })
        });
        console.log('request data loaded ==> ',arr)
        setData(arr)
        setRequestsLoaded(true)
    });
}

export let updateData = async (id) => {
    await updateDoc(doc(db, "Requests", id), {
        solved: true
    });
}
export let sendRequest = async (func,request) => {
    
    console.log('return of func ==> ',func)
    console.log('value to submit ===> ',request)
   
    await addDoc(collection(db, "Requests"), {...request});
   
    await updateDoc(doc(db, "Stats", "Requests"), {
        'requests.total': increment(1),
        [`requests.${func}`]: increment(1)
      
    });
    await updateDoc(doc(db, "Stats", "Requests","Yearly",year), {
        'total': increment(1),
        [`monthly.${month}`]: increment(1)
      
    });
}

let createUser = async (user) => {
    try{
        await setDoc(doc(db, "Users",user.uid), {
            displayName: user.displayName,
            email: user.email,
            profilePic: user.photoURL,
            permission: null,
            admin: false,
            joined: new Date,
            lastConnected: new Date,
        });

    }
    catch(e){
        console.log('An error occured ==> ',e)
    }
}

let updateConn = async (user) => {
    await updateDoc(doc(db, "Users",user), {
        'lastConnected': new Date,
    });
}

let checkUser = async (user, isUser,setLogin) => {
    const docRef = doc(db, "Users", user.uid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data()

    console.log('this user logged in before ===> ', user)
    
    if (docSnap.exists()) {
        console.log("Document data:", data);
        updateConn(user.uid)
        isUser(data)
        setLogin(true)

    } else {
      // docSnap.data() will be undefined in this case
      createUser(user)
      setLogin(false)
      console.log("No such document!");
    }
}

export let getStats = async (setData, setLoaded) => {
    let data = null;
    let dataYearly = null;
    let dataRequests = null;
    let dataYearlyRequests = null;

    const unsub1 = onSnapshot(doc(db, "Stats", "Visits"), (doc) => {
        data = doc.data();
        setDataAndUpdateLoaded();
    });

    const unsub2 = onSnapshot(doc(db, "Stats", "Visits", "Yearly", year), (doc) => {
        dataYearly = doc.data();
        setDataAndUpdateLoaded();
    });

    const unsub3 = onSnapshot(doc(db, "Stats", "Requests"), (doc) => {
        dataRequests = doc.data();
        setDataAndUpdateLoaded();
    });

    const unsub4 = onSnapshot(doc(db, "Stats", "Requests", "Yearly", year), (doc) => {
        dataYearlyRequests = doc.data();
        setDataAndUpdateLoaded();
    });

    const setDataAndUpdateLoaded = () => {
        if (data !== null && dataYearly !== null && dataRequests !== null && dataYearlyRequests !== null) {
            setData({
                yearly: data,
                requests: dataRequests,
                yearlyData: dataYearly?.monthly || null,
                yearlyRequests: dataYearlyRequests?.monthly || null,
                loaded: true
            });
            setLoaded(true);
        }
    };

    // Function to unsubscribe from snapshots
    const unsubscribeAll = () => {
        unsub1();
        unsub2();
        unsub3();
        unsub4();
    };

    // Return function to unsubscribe from snapshots when component unmounts
    return unsubscribeAll;
};


export let authWithGoogle = (isUser,setLogin) => {
    
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;

      console.log('user data ==> ', user)
      checkUser(user,isUser,setLogin)
     
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

export let authState = (isUser,setLogin) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          checkUser(user,isUser,setLogin)
        } else {
          // User is signed out
          // ...
        }
    });
}