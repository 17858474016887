import React, {useMemo, useEffect, useState} from 'react';
import ContactCard from '../../components/ContactCard';

const Contact = (props) => {
    const [dynamic, isDynamic] = useState(0)
    const [active, isActive] = useState('Billets')
    const [pillActive, isPillActive] = useState(false)
    
    const [activeAnim, setActiveAnim] = useState('https://lottie.host/1bc8d463-612e-4d73-a72e-fc2d754b8b32/eWFW5H2bF2.json')
    const [activeBG, setActiveBG] = useState('#26c6dacf')
  

    return(
        <div className={`topContainer animate__animated animate__fadeIn animate__faster`}>
            <div className='row mainContainer' style={{height:'75%'}}>
                <div className='col-12 col-md-6 h-100'>
                    <div className='customBox adapt braisedYellow' style={{overflow:'auto'}}>
                        <div className='containerBox two'>
                            <h1 className='title mb-4'>Contactez-nous</h1>
                            
                            <ContactCard 
                                Title='Addresse'
                                Desc=' HLM Grand Yoff, près de la Dibiterie Koromack Faye, Dakar'
                                Icon='map'
                                Type={null}
                            />

                            <ContactCard 
                                Title='Email'
                                Desc='universtravel.senegal@gmail.com'
                                Icon='email'
                                Type = 'email'
                                Contact
                            />

                            <ContactCard 
                                Title='Telephone 1'
                                Desc='+221 78 825 81 54'
                                Icon='phone'
                                Type = 'phone'
                                Contact
                            />

                            <ContactCard 
                                Title='Telephone 2'
                                Desc='+221 33 801 62 25'
                                Icon='phone'
                                Type = 'phone'
                                Contact
                            />

                            <ContactCard 
                                Title='Telephone 3'
                                Desc='+221 77 145 92 89'
                                Icon='phone'
                                Type = 'phone'
                                Contact
                            />
                           
                        </div>
                        
                    </div>
                </div>

                <div className='col-12 col-md-6 h-100'>
                    <div 
                        className='animate__animated animate__fadeIn map'
                        style={{height:'100%'}}
                    >
                        <iframe 
                            className='animate__animated animate__fadeIn'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.46829053025!2d-17.462335124257077!3d14.742626973582407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec10d46f62c2063%3A0x3bfa0572236288e4!2s344%20Rue%20GY-316%2C%20Dakar%2C%20Senegal!5e0!3m2!1sen!2sus!4v1712609688088!5m2!1sen!2sus"
                            width="100%" 
                            height="100%" 
                            style={{borderRadius:24}}
                            allowfullscreen="" 
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
            </div>

            <br/>

        
        </div>
    )
}

export default Contact