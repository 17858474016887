
import './App.css';
import logo from './assets/logo.png';
import logomini from './assets/logomini.png';

import React, {useMemo, useEffect, useState} from 'react';
import {BrowserRouter,Routes, Route} from 'react-router-dom'
import { authWithGoogle, authState } from './firebase/fb';


import Menu from './components/Menu';
import Home from './routes/Public/home';
import Contact from './routes/Public/contact';
import Services from './routes/services';
import Admin from './routes/Public/admin';


function App() {
  
  const [menu, isMenu] = useState('home')
  const [showLinks, setShowLinks] = useState(false)
  const [active, isActive] = useState('Accueil')
  const [direction, isDirection] = useState(0)
  const [loggedIn, setLogin] = useState(null)
  const [user, isUser] = useState(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  
 

  useEffect(() => {
    authState(isUser,setLogin)
  },[])
 
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path='/'
            element={
              <>
                {showLinks &&
                  <div className="fullscreen animate__animated animate__faster animate__fadeIn">

                    <span className="material-symbols-outlined" 
                      style={{
                        position:'absolute',
                        top: '3%',
                        right:'3%',
                        backgroundColor:'black',
                        borderRadius:100,
                        padding:5,
                        color:'white'
                      }} 
                      onClick={() => setShowLinks(false)}
                    >
                      close
                    </span>
                   
                  
                    
                    <div style={{width:345,margin:'auto',height:'100%',position:'relative',marginTop:115}}>
                      
                    
                      <br/>
                      
                      <h5 className={`mobileMenu ${menu == 'home'? 'active' : ''}`} onClick={() => {isMenu('home');setShowLinks(false)}}>Accueil</h5>
                      <h5 className={`mobileMenu ${menu == 'services'? 'active' : ''}`} onClick={() => {isMenu('services');setShowLinks(false)}}>A Propos</h5>
                      <h5 className={`mobileMenu ${menu == 'contact'? 'active' : ''}`} onClick={() => {isMenu('contact');setShowLinks(false)}}>Contact</h5>
                    </div>
                    
                  </div>
                }
                  
                <div className='row h-100'>
      
      
                  <div className='col-12 padded'>

                
                    <div className='w-100 d-flex'>
                      
                      <div className='d-flex' style={{justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                        <img className='logo' src= {window.innerWidth <= 565? logomini : logo }  />
                        {window.innerWidth <= 565 &&
                          <p className='mb-0'><b><span style={{color:'#292d84'}}>univers</span><span style={{color:'#e79436'}}>voyages</span></b></p>
                        }

                        <span 
                          className='hamburger material-symbols-outlined'
                          onClick={() => setShowLinks(true)}
                        >menu</span>
                      </div>
                     


                      <Menu 
                        Active={active} 
                        Title='Accueil' 
                        onClick={() => {
                          isMenu('home')
                          isActive('Accueil')
                          setTimeout(() => {
                            isDirection(0)
                          }, 850);
                        }} 
                      />
                      <Menu 
                        Active={active} 
                        Title='A Propros' 
                        onClick={() => {
                          isMenu('services')
                          isActive('A Propros')
                          setTimeout(() => {
                            isDirection(1)
                          }, 850);
                        }} 
                      />
                      <Menu 
                        Active={active} 
                        Title='Contact' 
                        onClick={() => {
                          isMenu('contact')
                          isActive('Contact')
                          setTimeout(() => {
                            isDirection(2)
                          }, 850)
                        }} 
                      />

                    
                    
                    </div>
                    
                    <br/>

                    {menu == 'home' &&
                      <Home/>
                    }

                    {menu == 'services' &&
                      <Services/>
                    }

                    {menu == 'contact' &&
                      <Contact/>
                    }
                  

                  
                  </div>
                </div>
              
              </>

              
            }
          />

          <Route 
            path='/admin'
            element={
              <>
                {loggedIn?
                  (<Admin User = {user} />)
                  :
                  (
                    <div 
                      className='w-100 h-100 d-flex'
                      style={{
                        backgroundColor:'#fffcf3',
                        overflow:'hidden',
                        justifyContent:'center',
                        alignItems:'center',
                      }}
                     
                    >
                      <div
                        className='d-flex'
                        style={{
                          width: 1000,
                          height:550,
                          justifyContent:'center',
                          alignItems:'center',
                          borderRadius:24,
                          backgroundColor: '#dfd9bd',
                          position:'relative'
                        }}
                      >
                        <img 
                          src={logo} 
                          style={{
                            position:'absolute',
                            width:350,
                            top:15
                          }}
                        />

                        <div
                          className='loginBtn'
                          onClick={() => authWithGoogle(isUser,setLogin)}
                        >
                          <img 
                            src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png' 
                            width={25}
                            style={{marginRight:15}}
                          />
                          Sign in with Google
                        </div>
                      </div>
                    </div>
                  )
                }
              </>
              
              
            }
          />
        </Routes>
      
      </div>
    </BrowserRouter>
  );
}

export default App;
