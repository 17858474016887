import { useState } from "react"

let ContactCard = (props) => {
    let [type, setType] = useState(props.Type)

    let handleContact = () => {
        type= 'email'? window.location.href = `mailto:${props.Desc}` : window.location.href = `tel:${props.Desc}`
    }
    return( 
        <div className='d-flex contactBox mb-3' onClick={() => props.Contact? handleContact() : null}>
            <span className='material-symbols-outlined' style={{marginRight:'5%',fontSize:28}}>{props.Icon}</span>

            <div className=''>
                <small>{props.Title}</small>
                <p className='mb-0 contactText' style={{fontSize:22}}>{props.Desc}</p>
            </div>
        </div>
    )
}

export default ContactCard