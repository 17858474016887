import { useEffect, useState } from 'react';
import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS

import StatCard from "../../components/Cards/StatCard";
import StatCardRound from "../../components/Cards/StatCardRound";

import {getStats} from '../../firebase/fb'


const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

let Home = (props) => {
    const statsRequest = props.StatsRequest
    const spentMonthly = props.SpentMonthly
    const perDepartment = props.PerDepartment
    const users = props.Users
    const loaded =false
    const [dataLoaded, setLoaded] = useState(0)
    const [update, isUpdate] = useState(0)
    const [totals, isTotals] = useState({
        total:0,
        unique:0
    })
    const [data, setData] = useState({
        yearly: null,
        requests: null,
        yearlyData: null,
        yearlyRequests: null,
        loaded: false
    })
    const [test,settest] = useState(null)

    const [yearlyData, setYearlyData] = useState([])
    const [yearlyRequests, isRequestsYearly] = useState([])

    const yearlyStats = {
        labels: months,
        datasets:[
            {
                label: 'Visites par mois',
                data: yearlyData
            },
            {
                label: 'Requetes par mois',
                data: yearlyRequests,
            }
        ]
    }
   

    let renderUsers = users?.map((data) => {
        let status = () => {
            if(!data.Permission) return 'Unassigned'
            else if(data.Permission == 'admin') return 'Admin'
            else if(data.Permission == 'basic') return 'Basic'
            else return 'Approver Lvl ' + data.Permission[data.Permission.length - 1]
        }
        return(
            <div className="card-inside">
                <div className="row">
                    <div className="col-3 text-center">{data.Name}</div>
                    <div className="col-3 text-center">{data.ID}</div>
                    <div className="col-3 text-center">{data.Joined.substring(0,10)}</div>
                    <div className="col-3 text-center">{status()}</div>
                </div>
            </div>
        )
        
    })

    useEffect(() => {
        getStats(setData, setLoaded)
        

        if(dataLoaded){
            console.log('done running 2')
            let arr = []
            let req = []
            const total = data?.yearlyData
            const requests = data?.yearlyRequests
    
            
            for (const month of months){
                
                
                (total && total[month])? arr.push(total[month].total) : arr.push(0)
                    
    
                requests && requests[month]? req.push(requests[month]) : req.push(0)
                    
            }
            setYearlyData(arr)
            isRequestsYearly(req)
           
        }
       
        
    },[dataLoaded])


    

    

    return(
        <div 
            className={`custom-card animate__animated animate__fadeIn`}
            
        >
            {!loaded?
                (
                    <div className="w-100 animate__animated animate__fadeIn" style={{height:'95%',overflowY:'auto',overflowX:'hidden'}}>

                        <div className="row">
        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
        
                                <StatCardRound
                                    Value = {data.yearly?.total}
                                    Title = 'Totale Visites' 
                                    BgColor = 'rgb(25, 118, 210)'
                                />
                            </div>
        
                            
        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCardRound
                                    Value = {data.yearly?.unique}
                                    Title = 'Totale Visites Unique' 
                                    BgColor = 'rgb(25, 118, 210)'
                                />
                            </div>

                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCardRound
                                    Value = {'4'}
                                    Title = 'Totale Requetes' 
                                    BgColor = 'rgb(25, 118, 210)'
                                />
                            </div>
        
        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCard
                                    Value = {data.requests?.requests?.ticket || 0}
                                    Title = 'Total Requetes Billets' 
                                    BgColor = '#1976D2'
                                />
                            </div>
                        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCard
                                    Value = {data.requests?.requests?.hotel || 0}
                                    Title = 'Total Requetes Hotel' 
                                    BgColor = '#388E3C'
                                />
                            </div>
        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCard
                                    Value = {data.requests?.requests?.car || 0}
                                    Title = 'Total Requetes Voitures' 
                                    BgColor = '#F57C00'
                                />
                            </div>
        
                            <div className="col-xl-2 col-6" style={{margin:'auto',marginTop:25}}>
                                <StatCard
                                    Value = {data.requests?.requests?.insurance || 0}
                                    Title = 'Total Requetes Assurance' 
                                    BgColor = '#546E7A'
                                />
                            </div>

                            <div className="col-xl-2 col-6" style={{margin:'auto',marginTop:25}}>
                                <StatCard
                                    Value = {data.requests?.requests?.visa || 0}
                                    Title = 'Total Requetes Visa' 
                                    BgColor = '#AB47BC'
                                />
                            </div>
        
                            <div className="col-xl-2 col-6" style={{margin:'auto',marginTop:25}}>
                                <StatCard
                                    Value = {data.requests?.requests?.tourism || 0}
                                    Title = 'Total Requetes Tourisme' 
                                    BgColor = '#8D6E63'
                                />
                            </div>
        
                          
                        </div>
        
                        <br/> 
        
                        <div className="row">
                        
                            <div className="col-12" style={{margin:'auto'}}>
                                <Chart
                                    type='bar'
                                    data={yearlyStats}
                                    height={'80%'}
                                    options={{ maintainAspectRatio: true }}
                                />
                            </div>
                        </div>
        
                        <br/>
        
                        {/* <div className="row">
        
                            <div className="col-12" style={{margin:'auto'}}>
        
                                <div className="user-card">
                                    <h6 className="mb-3">Registered Users</h6>
                                    <div className="card-inside">
                                        <div className="row" >
                                            <div className="col-3 text-center"><b>Name</b></div>
                                            <div className="col-3 text-center"><b>Email</b></div>
                                            <div className="col-3 text-center"><b>Joined</b></div>
                                            <div className="col-3 text-center"><b>Status</b></div>
                                        </div>
                                    </div>
        
                                    {renderUsers}
                                </div>
                            </div>
        
                        </div> */}
                    
        
                    
                    </div>
                )

                :

                (
                    <div className="w-100" style={{height:'95%',overflowY:'hidden',overflowX:'hidden'}}>

                        <div className="row">
        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="d-flex statCardRound skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="d-flex statCardRound skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="statCard skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
                        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="statCard skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="statCard skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
                        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="statCard skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
                            
                        </div>
        
                        <br/> <br/><br/> <br/>
        
                        <div className="row">
                        
        
                            <div className="col-xl-5 col-12" style={{margin:'auto',justifyContent:'center',alignItems:'center'}}>
                                <div className=" skeleton-box" style={{width:'50%',height:300,borderRadius:'100%',marginLeft:'35%'}}>
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
                            <div className="col-xl-7 col-12" style={{margin:'auto'}}>
                                <div className=" skeleton-box" style={{width:'100%',borderRadius:24,height:400}}>
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
                        </div>
        
                       
                    
        
                    
                    </div>
                )
            }
           
        </div>
    )
   
}

export default Home