let Pill = (props) => (
    <div 
        className={`col-4 col-md-2 text-center roundSelect braisedYellow ${props.Active == props.Title? 'active':''}`} 
        onClick={props.onClick}
    >
        <span class="material-symbols-outlined">{props.Icon}</span>
        <h6 className="mb-0 ellipsis" style={{marginLeft:'10%'}}>{props.Title}</h6>
    </div>
)

export default Pill