import React, { useState, useEffect, useMemo } from "react"

import FilterCard from "../../components/Cards/FilterCard";
import RequestCard from "../../components/Cards/RequestCard"
import Ring from "../../components/ring";

import { getRequests, updateData } from "../../firebase/fb";


let Requests = (props) => {
    
    const [data, setData] = useState([])
    const [requestsData,setRequestsLoaded] = useState(false)
    const [search, isSearching] = useState(false)
    const [searchRes, isSearchRes] = useState([])
    const [activeFilter,isActiveFilter] = useState('Par Defaut')
    const [currRequest, isCurrRequest] = useState(null)

   
   
    

    useEffect(() => {
        getRequests(setData,setRequestsLoaded)
    },[requestsData])

    let renderCards = data?.map((data) => (
        <RequestCard 
            Name = {data?.name?.first + ' ' + data?.name?.last}  
            Number={data?.email}
            Type = {data?.type}
            
            onClick={() => {
               isCurrRequest(data)
            }}
        />
    ))
    let searchCards = searchRes?.map((data) => (
        <RequestCard 
            Name = {data.name.first + ' ' + data.name.last}  
            Number={data.email}
            Type = {data.type}
            onClick={() => {
            isCurrRequest(data)
            }}
        />
    ))

    return (
        <div className="row h-100" >

            <div class="modal fade" id="detailsCard" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog modal-fullscreen">

                    <div className='w-100 h-100 center'>
                        <div className='customModal'>
                            <button 
                                type="button" 
                                class="btn-close custom" 
                                data-bs-dismiss="modal" aria-label="Close" 
                                style={{zIndex:9999}}
                            />
                            
                                <div className='row' style={{marginTop:'5%'}}>
                                    <div className='col-12'>
                                        
                                        <h1 className='title text-left mb-4' style={{textAlign:'left'}}>
                                            Details - Requete {currRequest?.type}

                                            <span 
                                                class="material-symbols-outlined"
                                                style={{fontSize:45,marginLeft:'5%',color:currRequest?.solved? '#4CAF50' : 'black'}}
                                            >
                                                {currRequest?.solved ?
                                                    (<>check_circle</>)
                                                    :
                                                    (<>hourglass_top</>)
                                                }
                                            </span>
                                        </h1>
                                        
                                        
                                        <div className='row mb-3'>
                                            <div className='col-6'>
                                                <div class="form-floating mb-3">
                                                    <input 
                                                        class="form-control custom"
                                                        placeholder="John" 
                                                        value={currRequest?.name.last}
                                                        disabled 
                                                    />
                                                    <label for="floatingInput">Nom</label>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div class="form-floating mb-3">
                                                    <input 
                                                        class="form-control custom"
                                                        placeholder="John" 
                                                        value={currRequest?.name.first}
                                                    />
                                                    <label for="floatingInput">Prenom</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mb-3'>
                                            <div className='col-6'>
                                                <div class="form-floating mb-3">
                                                    <input 
                                                        class="form-control custom"
                                                        placeholder="John" 
                                                        value={currRequest?.email}
                                                        disabled
                                                    />
                                                    <label for="floatingInput">Email</label>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div class="form-floating mb-3">
                                                    <input 
                                                        class="form-control custom"
                                                        placeholder="John" 
                                                        value={currRequest?.phone}
                                                    />
                                                    <label for="floatingInput">Telephone</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mb-3'>
                                            <div className='col-6'>
                                                <div class="form-floating mb-3">
                                                    <input 
                                                        class="form-control custom"
                                                        placeholder="John" 
                                                        value={currRequest?.destination.from}
                                                        disabled
                                                    />
                                                    <label for="floatingInput">Origine</label>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div class="form-floating mb-3">
                                                    <input 
                                                        class="form-control custom"
                                                        placeholder="John" 
                                                        value={currRequest?.destination.to}
                                                        disabled  
                                                    />
                                                    <label for="floatingInput">Destination</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mb-3'>
                                            <div className='col-6'>                          
                                                <div class="form-floating mb-3">
                                                    <input 
                                                        class="form-control custom"
                                                        placeholder="John" 
                                                        value={currRequest?.dates.from.toDate()}
                                                        disabled  
                                                    />
                                                    <label for="floatingInput">Date De Depart</label>
                                                </div>
                                            </div>

                                            <div className='col-6'>                          
                                                <div class="form-floating mb-3">
                                                    <input 
                                                        class="form-control custom"
                                                        placeholder="John" 
                                                        value={currRequest?.dates.to.toDate()}
                                                        disabled  
                                                    />
                                                    <label for="floatingInput">Date De Depart</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mb-4'>
                                            <div className='col-12'>
                                                <div class="form-floating">
                                                    <textarea 
                                                        class="form-control custom" 
                                                        placeholder="Leave a comment here" 
                                                        style={{height:100}}
                                                        value={currRequest?.comments}
                                                        disabled
                                                    />
                                                    <label for="floatingTextarea2">Commentaires (Details supplementaires)</label>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        {!currRequest?.solved &&
                                            <div className='row mb-3'>
                                                <div className='col-12 center' >
                                                    <div 
                                                        className='customBtn' 
                                                        onClick={() => updateData(currRequest?.id)}
                                                        data-bs-dismiss="modal" aria-label="Close" 
                                                    >Confirmer</div>
                                                </div>
                                            </div>
                                        }
                                       
                                        

                                    </div>
                                    
                                </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
          
            <div className="col-12 h-100">
                <div className={`custom-card animate__animated animate__fadeIn`}>

                    <div className='w-100 mb-3 text-center' style={{position:'relative',margin:'auto'}}>
                        <span class="material-symbols-outlined search-icon">search</span>
                        <input 
                        
                            placeholder='Search for request...' 
                            className='searchCustom text-center'
                            onChange={(e) => {
                                let text = e.target.value
                                console.log(text)
                                
                                if(text.length > 0){
                                    isSearching(true)
                                    isSearchRes(data.filter(mydata => mydata.name.first.toLowerCase().includes(text) || mydata.name.last.toLowerCase().includes(text) || mydata.type.toLowerCase().includes(text) || mydata.email.toLowerCase().includes(text)))
                                }
                                else{
                                    isSearching(false)
                                }
                                
                            
                            }}
                        />
                        
                    </div>

                    <div className="filterContainer d-flex w-100 justify-content-center" style={{position:'relative'}}>
                        <p >Filter by</p>
                    </div>
                    <div className="d-flex w-100" style={{marginTop:'2.5%',margin:'auto',marginBottom:'2%'}}>
                        
                        

                        <div className="filterContainer d-flex w-100 justify-content-center" style={{position:'relative'}}>
                            {/* <p style={{position:'absolute',left:'22%',marginTop:10}}>Filter by</p> */}

                            <FilterCard
                                Name='Par Defaut'
                                Active={activeFilter} 
                                onClick={() => {
                                    isSearching(false)
                                    isActiveFilter('Par Defaut')
                                }}
                                
                            />

                            <FilterCard
                                Name='Confirmer'
                                Active={activeFilter} 
                                onClick={() => {
                                    isSearching(true)
                                    isSearchRes(data.filter(mydata => mydata.solved ))
                                    isActiveFilter('Confirmer')
                                }}
                                
                            />  

                            <FilterCard
                                Name='Non Confirmer'
                                Active={activeFilter} 
                                onClick={() => {
                                    isSearching(true)
                                    isSearchRes(data.filter(mydata => !mydata.solved ))
                                    isActiveFilter('Non Confirmer')
                                }}
                                
                            />  

                           
                        </div>
                      
                        
                      
                        
                    </div>

                    <div className="row w-100" style={{margin:'auto'}}>
                        <>
                            {!search?
                                (
                                    <>{renderCards}</>
                                )
                                :
                                (
                                    <>{searchCards}</>
                                )
                            }
                        
                        </>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default Requests