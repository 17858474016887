/* global $ */
import React, {useMemo, useEffect, useState, useRef} from 'react';
import Pill from '../../components/Pill';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import {Modal} from 'bootstrap'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { trackVisits, sendRequest } from '../../firebase/fb';






let Home = () => {
    const [dynamic, isDynamic] = useState(0)
    const [active, isActive] = useState('Billets')
    
    const [activeAnim, setActiveAnim] = useState('https://lottie.host/1bc8d463-612e-4d73-a72e-fc2d754b8b32/eWFW5H2bF2.json')
    const [activeBG, setActiveBG] = useState('#26c6dacf')

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [lastname, setLastName] = useState('')
    const [firstname, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [orign, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const [commentary, setCommentary] = useState('')

    const [highlight, setHighlight] = useState(false)
  
    const [submitted,isSubmitted] = useState(false)
    const [animated,isAnimated] = useState(false)
    const modalRef = useRef()

    const descr = [
      {
        title: "BILLETS D'AVION",
        text:'Réserver vos billets d’avions de façon simple et rapide avec univers voyages. Des billets d’avions vers toutes destinations.',
        img: 'https://i.pinimg.com/originals/cf/4e/7e/cf4e7ef82f683fcc564d78e786511559.gif'
      },
      {
        title: 'HÔTELS',
        text: 'Faciliter vos démarches de réservation de chambres d’hôtel avec Univers voyages, qui vous propose des chambres de luxe dans les beaux hôtels du monde.',
        img: 'https://i.pinimg.com/originals/cb/f7/35/cbf7354fd8d639f377726a386a5c1e5b.gif'
      },
      {
        title: 'LOCATION DE VOITURES',
        text: "Univers voyages vous offre un Service de location de véhicules toutes catégories, pour vos déplacements, excursions touristiques, et navette Aéroport-Hôtel/Lieu de résidence, à l'aller comme au retour",
        img: 'https://cdn.dribbble.com/users/1498581/screenshots/3622197/flat_rigged_car.gif'
      },
      {
        title: 'ASSURANCES MULTIFORMES',
        text:'Univers Voyages vous propose un service d’assurance voyages et vehicles avec une panoplie complète adaptée à vos exigences et préoccupations spécifiques.',
        img: 'https://cdn.dribbble.com/users/472136/screenshots/2321171/secure-area.gif'
      },
    
      {
        title: 'ASSISTANCES VISAS',
        text: "Univers Voyages vous offre un service d'accompagnement et d'assistance dans vos démarches de Visas pour tous les pays du monde.",
        img: 'https://media.tenor.com/1DnP7Pj82VYAAAAM/american-experience-ride-the-wave.gif'
      },
     
      {
        title: 'TOURISME',
        text:  'Univers Voyages vous offre la possibilité d’effectuer des excursions touristiques dans les villes, coins, et endroits les plus touristiques du Sénégal.',
        img: 'https://cdn.dribbble.com/users/1523644/screenshots/3360892/tourist-big.gif'
      },
      
      
     
    ]

    const toReqType = () => {
        if(active == 'Billets') return 'ticket'
        else if(active == 'Hotel') return 'hotel'
        else if(active == 'Voiture') return 'car'
        else if(active == 'Assurance Voyage') return 'insurance'
        else if(active == 'Assistance Visa') return 'visa'
        else return 'tourism'
    }

    const validateForm = () => {
        console.log('validate form')
        if(
            lastname.length > 0 &&
            firstname.length > 0 &&
            (email.length > 0 && email.includes('@') && email.includes('.')) &&
            phone.length > 0 &&
            orign.length > 3 &&
            destination.length > 3
        ){
            setHighlight(false)
            return true
        }
        else{
            console.log('conditions not met')
            setHighlight(true)
        }
        
    }

    const resetForm = () => {
        setStartDate(new Date());
        setEndDate(new Date());
        setLastName('')
        setFirstName('')
        setEmail('')
        setPhone('')
        setOrigin('')
        setDestination('')
        setCommentary('')

        setTimeout(() => {
            $('#contactForm').modal('hide')
        }, 7000);
    }

    
    useEffect(() => {
        trackVisits()   
    },[])

   

    return(
        <>
            <div className="modal" id="contactForm" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog modal-fullscreen">

                    <div className='w-100 h-100 center'>
                        <div className='customModal'>
                            <button 
                                type="button" 
                                class="btn-close custom" 
                                data-bs-dismiss="modal" aria-label="Close" 
                                style={{zIndex:9999}}
                            />
                            {submitted?
                                (
                                    <>
                                        {animated?
                                            (
                                                <div className='w-100 h-100 animate__animated animate__fadeIn' style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
                                                    <h1 className='w-75'>Demande envoye, nous vous reviendrons des que possible!</h1>
                                                </div>
                                            )
                                            :
                                            (
                                                <Player
                                                    autoplay
                                                    src='https://lottie.host/f9e01ebb-66c2-465c-8b34-fce0dbdd5249/qj3ZaXMAgJ.json'
                                                    style={{
                                                        width:'100%',
                                                        height:'100%',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        // marginBottom:'30%'
                                                    }}
                                                    onEvent={event => {
                                                        console.log('animation state ==> ', event)
                                                        
                                                        if (event === 'pause') isAnimated(true)
                                                       
                                                    }}
                                                    onFrameChange={(e) => console.log('current frame ===> ',e)}
                                                />
                                            )
                                        }
                                    </>
                                    
                                   
                                )

                                :

                                (
                                    
                                    <div className='row' style={{marginTop:'5%'}}>
                                        <div className='col-12'>
                                            <h1 className='title text-left mb-4' style={{textAlign:'left'}}>Reservez un billet</h1>

                                            <div className='row mb-3'>
                                                <div className='col-12 col-md-6'>
                                                    <div class="form-floating mb-3">
                                                        <input 
                                                            class={`form-control custom ${lastname.length < 1 && highlight? 'validate animate__animated animate__headShake' : ''}`}
                                                            placeholder="John" 
                                                            value={lastname}
                                                            onChange={(e) => setLastName(e.target.value)}  
                                                        />
                                                        <label for="floatingInput">Nom</label>
                                                    </div>
                                                </div>

                                                <div className='col-12 col-md-6'>
                                                    <div class="form-floating mb-3">
                                                        <input 
                                                            class={`form-control custom ${firstname.length < 1 && highlight? 'validate animate__animated animate__headShake' : ''}`}
                                                            placeholder="John" 
                                                            value={firstname}
                                                            onChange={(e) => setFirstName(e.target.value)}  
                                                        />
                                                        <label for="floatingInput">Prenom</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-3'>
                                                <div className='col-12 col-md-6'>
                                                    <div class="form-floating mb-3">
                                                        <input 
                                                            class={`form-control custom ${(email.length < 1 && !email.includes('@') && !email.includes('.')) && highlight? 'validate animate__animated animate__headShake' : ''}`}
                                                            placeholder="John" 
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}  
                                                        />
                                                        <label for="floatingInput">Email</label>
                                                    </div>
                                                </div>

                                                <div className='col-12 col-md-6'>
                                                    <div class="form-floating mb-3">
                                                        <input 
                                                            class={`form-control custom ${phone.length < 1 && highlight? 'validate animate__animated animate__headShake' : ''}`}
                                                            placeholder="John" 
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}  
                                                        />
                                                        <label for="floatingInput">Telephone</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-3'>
                                                <div className='col-12 col-md-6'>
                                                    <div class="form-floating mb-3">
                                                        <input 
                                                            class={`form-control custom ${orign.length < 4 && highlight? 'validate animate__animated animate__headShake' : ''}`}
                                                            placeholder="John" 
                                                            value={orign}
                                                            onChange={(e) => setOrigin(e.target.value)}  
                                                        />
                                                        <label for="floatingInput">Origine</label>
                                                    </div>
                                                </div>

                                                <div className='col-12 col-md-6'>
                                                    <div class="form-floating mb-3">
                                                        <input 
                                                            class={`form-control custom ${destination.length < 4 && highlight? 'validate animate__animated animate__headShake' : ''}`}
                                                            placeholder="John" 
                                                            value={destination}
                                                            onChange={(e) => setDestination(e.target.value)}  
                                                        />
                                                        <label for="floatingInput">Destination</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-3'>
                                                <div className='col-12 col-md-6'>                          
                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" style={{backgroundColor:'#d6d4b2'}}>Date De Depart</span>
                                                        <div class="form-floating">
                                                            <DatePicker className='customdate' selected={startDate} onChange={(date) => setStartDate(date)} />
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-12 col-md-6'>
                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" style={{backgroundColor:'#d6d4b2'}}>Date De Depart</span>
                                                        <div class="form-floating">
                                                            <DatePicker className='customdate' selected={endDate} onChange={(date) => setEndDate(date)} />
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-4'>
                                                <div className='col-12'>
                                                    <div class="form-floating">
                                                        <textarea 
                                                            class="form-control custom" 
                                                            placeholder="Leave a comment here" 
                                                            style={{height:100}}
                                                            value={commentary}
                                                            onChange={(e) => setCommentary(e.target.value)}  
                                                        />
                                                        <label for="floatingTextarea2">Commentaires (Details supplementaires)</label>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div className='row mb-3'>
                                                <div className='col-12 center' >
                                                    <div 
                                                        className='customBtn' 
                                                        onClick={() => {
                                                            
                                                            if(validateForm()){
                                                                sendRequest(toReqType(),{
                                                                
                                                                    name: {
                                                                        first: firstname,
                                                                        last: lastname
                                                                    },
                                                                    destination:{
                                                                        from: orign,
                                                                        to: destination
                                                                    },
                                                                    dates: {
                                                                        from: startDate,
                                                                        to: endDate
                                                                    },
                                                                    comments: commentary,
                                                                    email: email,
                                                                    phone: phone,
                                                                    type: active,
                                                                    solved: false,
                                                                    submitted: new Date
                                                                })
                                                                isSubmitted(true)
                                                                resetForm()
                                                            }
                                                            
                                                        }}
                                                    >Soumettre</div>
                                                </div>
                                            </div>
                                            

                                        </div>
                                        
                                    </div>
                                    
                                )
                            }
                            
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className={`topContainer animate__animated animate__fadeIn animate__faster`}>

                <div className='row mainContainer adapt'>
                    <div className='col-12 col-md-6 lottie1'>
                        <div className='customBox descr braisedYellow'>
                            <div className='containerBox'>
                                <h1 className='title'>{descr[dynamic].title}</h1>
                                <p className='dynamicText'>{descr[dynamic].text}</p>
                                
                                <div className='contactBtn' data-bs-toggle="modal" data-bs-target="#contactForm">Reservez</div>
                            </div>
                            
                            
                        </div>
                    </div>

                    <div className='col-12 col-md-6 lottie2'>
                        <div 
                            className='animate__animated animate__fadeIn player'
                        >
                            <Player
                                autoplay
                                loop
                                src={activeAnim}
                                background={activeBG}
                                style={{
                                    width:'100%',
                                    height:'100%',
                                    justifyContent:'center',
                                    alignItems:'center',
                            
                                    borderRadius:24
                                }}
                            />
                        </div>
                    </div>
                </div>

                <br/>

                <div className='row roundCont braisedYellow'>
                
                    <Pill 
                        Title='Billets'
                        Icon='flight'
                        Active={active}
                        onClick={() => {
                            setActiveAnim('https://lottie.host/1bc8d463-612e-4d73-a72e-fc2d754b8b32/eWFW5H2bF2.json')
                            setActiveBG('#26c6dacf')
                            isDynamic(0)
                            isActive('Billets')
                        }}
                    />

                    <Pill 
                        Title='Hotel'
                        Icon='hotel'
                        Active={active}
                        onClick={() => {
                            setActiveAnim('https://lottie.host/f45db1e4-175b-494e-a578-699b449efcc0/qt2VegUImY.json')
                            setActiveBG('#FBC02D')
                            isDynamic(1)
                            isActive('Hotel')
                        }}
                    />
                    
                    <Pill 
                        Title='Voiture'
                        Icon='directions_car'
                        Active={active}
                        onClick={() => {
                            setActiveAnim('https://lottie.host/8dfc9ec5-5ebc-49ae-87c3-bacfbbdb58e1/VbhwLNkkAL.json')
                            setActiveBG('#FF7043')
                            isDynamic(2)
                            isActive('Voiture')
                        }}
                    />
                
                    <Pill 
                        Title='Assurance Multi'
                        Icon='luggage'
                        Active={active}
                        onClick={() => {
                            setActiveAnim('https://lottie.host/c9ab34c0-be3a-47be-b9dd-d6dace594535/hyzH9WZj6q.json')
                            setActiveBG('#81C784')
                            isDynamic(3)
                            isActive('Assurance Multi')
                        }}
                    />
            
                    <Pill 
                        Title='Assistance Visa'
                        Icon='handshake'
                        Active={active}
                        onClick={() => {
                            setActiveAnim('https://lottie.host/9c6d8d92-db49-4836-8f97-0ecc6f12c675/W881ozHozt.json')
                            setActiveBG('#00ACC1')
                            isDynamic(4)
                            isActive('Assistance Visa')
                        }}
                    />
                    
                    <Pill 
                        Title='Tourisme'
                        Icon='map'
                        Active={active}
                        onClick={() => {
                            setActiveAnim('https://lottie.host/e44e3540-83c3-435f-aaa6-6fb84d5c29dc/cGnYehmuwA.json')
                            setActiveBG('#4DB6AC')
                            isDynamic(5)
                            isActive('Tourisme')
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default Home