import React, { useState, useEffect, useContext, useMemo } from "react"
import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS


import Navbar from "../../components/Navbar";
import BottomNavbar from "../../components/BottomNavbar";
import FilterCard from "../../components/Cards/FilterCard";
import RequestCard from "../../components/Cards/RequestCard"
import Ring from "../../components/ring";

import StatCard from "../../components/Cards/StatCard";
import StatCardRound from "../../components/Cards/StatCardRound";

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import Home from "../Admin/Home";
import Requests from "../Admin/Requests";
import Settings from "../Admin/Settings";



/* eslint-disable padded-blocks */
 

let Admin = (props) => {

    const [data, isData] = useState([])
    const [selected, isSelected] = useState('Dashboard')
    const user = props.User
    const [loaded, isLoaded] = useState(false)
   

  
    return (
        <div className="row" style={{marginTop:'1.5%',height:'93%'}} >

       
          
            <div className='col-xl-2 col-lg-2 col-md-2 d-flex h-100 mobilecontrol' style={{justifyContent:'center',alignItems:'center'}}>
                <Navbar 
                    onClick1={() => isSelected('Dashboard')}
                    onClick2={() => isSelected('Requests')}
                    onClick3={() => isSelected('Settings')}
                    Selected = {selected}
                />
            </div>

            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 h-100">
                {selected == 'Dashboard' &&
                    <Home Loaded={loaded}  />
                }

                {selected == 'Requests' &&
                    <Requests Data={data} />
                }

                {selected == 'Settings' &&
                    <Settings User={user} />
                }

                {/* <BottomNavbar 
                    onClick1={() => isSelected('Dashboard')}
                    onClick2={() => isSelected('Requests')}
                    onClick3={() => isSelected('Settings')}
                    Selected = {selected}
                /> */}
               
            </div>
           
        </div>
    )
}

export default Admin