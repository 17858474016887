const RequestCard = (props) => {
    
    let typeIndicator = () => {
        if(props.Type == 'Billets') return 'https://img.freepik.com/premium-vector/flat-airplane-illustration_118124-461.jpg'
        else if (props.Type == 'Hotel') return 'https://cdn-icons-png.flaticon.com/512/235/235889.png'
        else if (props.Type == 'Voiture') return 'https://cdn-icons-png.flaticon.com/512/9210/9210634.png'
        else if (props.Type == 'Assurance Voyage') return 'https://cdn-icons-png.flaticon.com/512/4960/4960792.png'
        else if (props.Type == 'Assistance Visa') return 'https://cdn-icons-png.flaticon.com/512/146/146221.png'
        else return 'https://cdn-icons-png.flaticon.com/512/236/236998.png'
    }
    
    return(
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">                            
            <div className={`d-flex requestCard animate__animated animate__fadeIn`} onClick={props.onClick} data-bs-toggle="modal" data-bs-target="#detailsCard"> 
                
                <div className="col-4">
                    <img 
                        src={typeIndicator(props.Type)}
                        width={75}
                        style={{
                            borderRadius:'100%',
                            backgroundSize:'cover'
                        }}
                    />
                </div>
                
                <div className="col-8 desc-container">
                    <p className="ellipsis"><b>Nom -</b> {props.Name}</p>
                    <p className="ellipsis"><b>Numero -</b> {props.Number}</p>
                    <p className="mb-0"><b>Type -</b> {props.Type}</p>
                </div>
            </div>
        </div>
    )
}
export default RequestCard