import React, {useMemo, useEffect, useState} from 'react';
import ContactCard from '../components/ContactCard';

const Services = (props) => {
    const [dynamic, isDynamic] = useState(0)
    const [active, isActive] = useState('Billets')
    const [pillActive, isPillActive] = useState(false)
    
    const [activeAnim, setActiveAnim] = useState('https://lottie.host/1bc8d463-612e-4d73-a72e-fc2d754b8b32/eWFW5H2bF2.json')
    const [activeBG, setActiveBG] = useState('#26c6dacf')
  

    return(
        <div className={`topContainer animate__animated animate__fadeIn animate__faster`}>
            <div className='row mainContainer' style={{height:'75%'}}>
                <div className='col-12 h-100'>
                    <div className='customBox braisedYellow'>
                        <div className='containerBox three'>
                            <h1 className='title mb-4'>A Propos De Nous</h1>
                            
                            <p className='infoText'>
                                Univers Voyages, est une Agence de Voyages et de Tourisme, ouverte à Dakar en 2019 pour offrir aux clients et usagers une multitude de services liés au Voyage et au Tourisme.
                            </p>

                            <p className='infoText'>
                                En effet, Univers Voyages, en plus de sa vocation première qui est de vendre des billets d'avion, toutes compagnies et toutes destinations, propose des assurances voyages, réservation d'hôtels, location de véhicules, transfert, excursions touristiques, et assistance visas pour certains pays.
                            </p>

                            <p className='infoText'>
                                Ainsi, Univers Voyages, avec ses diverses activités liées à l'environnement du Voyages et du Tourisme, vous permet de trouver tout ce dont vous avez besoin dans un seul et même endroit, et vous permet ainsi centraliser vos différents besoins et requêtes, d'économiser de l'énergie, et de bénéficier d'un gain de temps considérable.
                            </p>

                            <img className='animate__animated animate__flipInX ornaments bottomRight' src={require('../assets/baggage.png')} />
                            <img className='animate__animated animate__flipInX ornaments topRight' src={require('../assets/camera.png')} />
                            <img className='animate__animated animate__flipInX ornaments bottomLeft' src={require('../assets/something.png')} />
                           
                        </div>
                        
                    </div>
                </div>

             
            </div>

            <br/>

        
        </div>
    )
}

export default Services